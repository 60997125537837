/* .title-hr hr {
    display: inline-block;
    width: 10%;
    margin: 5px 10px;
    border-top: 2px solid #e5e5e5;
} */

.flex {
    display: flex;
}
  
header {
    display: flex;
    color: black;
}
  
  header:before,
  header:after {
    content: '';
    margin: auto 1em;
    border-bottom: solid 1px;
    flex: 1;
  }
  
  h4 {
      position: relative;
      padding: 0.25em 1em;
      overflow: hidden;
      background: linear-gradient(currentcolor, currentcolor) no-repeat top center, linear-gradient(currentcolor, currentcolor) no-repeat bottom center;
      background-size: calc(100% - 1.65em) 1px;
    }
    
    h4:before,
    h4:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border: solid 1px;
      border-top: none;
      border-bottom: none;
      transform: skew(45deg);
    }
    
    h4:after {
      transform: skew(-45deg)
    }

    #sidenavbar {
      /* width: 220px;
      min-width: 220px; */
      padding-right: 16px;
      align-self: flex-start;
      position: -webkit-sticky;
      position: sticky;
      top: 48px;
      max-height: calc(100vh - 70px);
      overflow: visible;
      margin-top: 50px;
    }

    ul {
      list-style-type: none;
    }

    .text {
      font-size: 30px; /* Default for large screens */
    }
    
    @media (max-width: 768px) {
      .text {
        font-size: 24px; /* Smaller for tablets */
      }
    }
    
    @media (max-width: 480px) {
      .text {
        font-size: 16px; /* Even smaller for mobile */
      }
    }