  #top {
    min-height: 100vh;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(6px + 2vmin);
    color: rgba(0,0,0,.9);
    width: 100%;
  }

  #profile * {
    padding: 2%;
  }

  #bottom {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: black;

    background-image: url(https://dmjsacw2lb7bt.cloudfront.net/main/dubrovnik_2018-68.png);
    background-size: cover;
    content: "";
    /* display: block; */
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    z-index: -2;
    /* opacity: 0.4; */
  }

  #intro {
    padding-top: 10%;
    text-align: left;
  }
  
  .rounded-circle {
    width: 18%;
  }

  @media (max-width: 768px) {
    .rounded-circle {
      width: 23%; /* Smaller for tablets */
    }
  }
  
  @media (max-width: 480px) {
    .rounded-circle {
      width: 40%; /* Even smaller for mobile */
    }
  }