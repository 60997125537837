.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

/* body {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
} */

.spinner {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.spinner:after {
  animation: changeContent .8s linear infinite;
  display: block;
  content: "⠋";
  font-size: 80px;
}

@keyframes changeContent {
  10% { content: "⠙"; }
  20% { content: "⠹"; }
  30% { content: "⠸"; }
  40% { content: "⠼"; }
  50% { content: "⠴"; }
  60% { content: "⠦"; }
  70% { content: "⠧"; }
  80% { content: "⠇"; }
  90% { content: "⠏"; }
}