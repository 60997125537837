.container {
    padding-top: 3%;
}

.row {
    padding-top: 3%;
}

#top {
    min-height: 100vh;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(6px + 2vmin);
    color: rgba(0, 0, 0, .9);
    width: 100%;
}

.spinner {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  
  .spinner:after {
    animation: changeContent .8s linear infinite;
    display: block;
    content: "⠋";
    font-size: 80px;
  }

  .image-container {
    position: relative;
  }
  
  .hover-bar {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .image-container:hover .hover-bar {
    opacity: 1;
  }